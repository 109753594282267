.modal-subheading {
  font: normal normal 600 14px/20px Quicksand;
  letter-spacing: 0px;
  color: #111111;
  text-align: left;
}
body.overFlow{
  overflow: hidden;
}
.rating-btn {
  display: flex;
  align-items: center;
}

.rating-btn-open {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 10px;
  width: 300px;
  margin-right: 2px;
}

a.btn.button-primary.report {
  font: normal normal bold 18px/23px Quicksand;
  letter-spacing: 0.72px;
  color: #FFFFFF;
  text-transform: capitalize;
  line-height: 40px;
}

.rating-btn-open h5 {
  font: normal normal 600 14px/12px Quicksand;
  letter-spacing: 0px;
  color: #292929;
  margin: 0;
  padding: 0;
  margin-right: 10px;
  
}
.swal2-popup.swal2-modal{
  width: 24em;
  border-radius: 15px;
}

.swal2-popup.swal2-modal .swal2-html-container{
  font-size: 14px;
  font-weight: 500;
}
.swal2-icon.swal2-warning.swal2-icon-show{
  width: 4em;
    height: 4em;
}
.swal2-actions{
  font-size: 14px;
}
.swal2-confirm.swal2-styled, .swal2-cancel.swal2-styled{

  border-radius: 25px;
  padding: 0.5em 2em;
  font-size: 15px;
}

.rating-btn-open h6 {
  color: #111;
  margin: 0;
  padding: 0;
  font: normal normal 500 14px/12px Quicksand;
  margin-right: 10px;
}

.customer-detail img.user {
  height: 30px !important;
  width: 30px;
}

.btn-detail {
  padding: 10px 0px;
}

.customer-detail img.more {
  height: auto !important;
  margin-left: 10px;
}

.small-h6 {
  font: normal normal 500 14px/14px Quicksand;
  letter-spacing: 0px;
  color: #5A5A5A;
  margin: 0;
}

.small-data {
  font: normal normal 600 18px/42px Quicksand;
  letter-spacing: 0px;
  color: #111111;
  margin: 0;
}

/* common css */
p {
  word-wrap: break-word !important;
}

.info_icon img {
  cursor: pointer;
}

.radio_box,
.radio_box label {
  cursor: pointer;
}

a {
  cursor: pointer;
  color: #8174B2
}


a:hover,

a:focus {

  text-decoration: none;

  color: #8174B2;

}

a.link {

  color: inherit;

}

/* form css */
input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="tel"],
select,
#country-search-box,
.form-control {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 58px;
  line-height: 1.42857143;
  color: #3E3F40 !important;
  border: none;
  border: 1px solid #BFBFBF;
  outline: none;
  border-radius: 10px;
  box-shadow: none;
  background: #fff 0% 0% no-repeat padding-box;
  padding: 8px 14px;
  font-size: 16px;
  font-weight: 500;
  resize: none;
}

textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 100px;
  line-height: 1.42857143;
  color: #3E3F40 !important;
  border: none;
  border: 1px solid #BFBFBF;
  outline: none;
  border-radius: 10px;
  box-shadow: none;
  background: #fff 0% 0% no-repeat padding-box;
  padding: 8px 14px;
  font-size: 16px;
  font-weight: 500;
  resize: none;
}

input[type="text"],input[type="number"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="tel"],
select,
#country-search-box,
.form-control,
.custom-padding,
textarea {
  padding: 8px 35px 8px 14px;
}




.color_text {
  color: #3E3F40 !important;
}

/* form css */
input[type="text"]::placeholder,
input[type="number"]::placeholder,
input[type="password"]::placeholder,
input[type="email"]::placeholder,
input[type="tel"]::placeholder,
input[type="search"]::placeholder {
  color: #b2b2b2;
  font-size: 14px;
  font-weight: 400;
}

textarea::placeholder {
  color: #b2b2b2;
  font-size: 14px;
  font-weight: 400;
}

/*-webkit-appearance: none;-moz-appearance: none;appearance: none;*/

.form-group {
  position: relative;
  margin: 25px 0;
  display: inline-block;
  width: 100%;
}

.login-section .form-group {
  position: relative;
  margin: 10px 0;
}

.form-group select {
  /* background: rgba(0, 0, 0, 0) url(./assets/images/ic_up_down.svg) no-repeat scroll calc(96% + 3px) center/8px auto; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


.form-group .icon_div select {
  background: none;
  cursor: pointer;
}

.icon_div {
  position: relative;
}

.icon_div:after {
  width: 15px;
  height: 10px;
  content: "";
  position: absolute;
  right: 15px;
  top: 25px;
  background: url(./assets/images/ic_dropdown.png) no-repeat;
}

.charCount {
  font: normal normal medium 14px/42px Quicksand;
  letter-spacing: 0.8px;
  color: #5A5A5A;
  position: absolute;
  right: 0px;
  margin-top: 3px;
}

.form-group input:focus {
  outline: none;
}

.greenbox.greentbox,
.accordionlist li h6,
.whtappliesBtn {
  word-wrap: break-word;
}

.calculatedTotal ul li p.priceName {
  width: 80%;
  text-align: left;
}

.calculatedTotal ul li p.price {
  width: 20%;
  text-align: right;
}

.no-data-found {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}


.form-group label {
  font-weight: 500;
  -ms-transition: all 200ms ease 0s;
  -webkit-transition: all 200ms ease 0s;
  transition: all 200ms ease 0s;
  color: #0e1217;
  letter-spacing: 1px;
  margin-bottom: 3px;
  display: block;
  margin-left: 8px;
  word-wrap: break-word;
}


.list .form-group label {
  white-space: normal;
}

.form-group label.checkbox {
  cursor: pointer;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

.cover-label {
  position: relative;
}

.cover-label span.currency-name {
  position: absolute;
  right: 12px;
  top: 1px;
  display: inline-block;
  font-size: 20px;
  letter-spacing: 0px;
  color: #121212;
  background: #fff;
  width: 50px;
  line-height: 55px;
  text-align: center;
}

.form-group .cover-label label {
  position: absolute;
  top: -10px;
  left: 5px;
  color: #707070;
  opacity: 1;
  font-size: 14px;
  background: #fff;
  padding-left: 2px;
  z-index: 2;

}

.free {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  padding: 0px 50px;
}

.or {
  text-align: center;
  position: relative;
}

.or span {
  display: inline-block;
  padding: 0px 20px;
  color: #5A5A5A;
  font-size: 17px;
  font-weight: 500;
  background: #fff;
  position: relative;
  z-index: 1;
}

.or:after {
  content: '';
  border-top: 1px solid #E2E7E7;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 14px;
}

.forgot_password {
  padding: 150px 0px 0px;
  text-align: center;
}

.forgot_password a {

  font: normal normal bold 17px/23px Quicksand;
  letter-spacing: 0.72px;
  color: #8174B2;
  /* text-transform: capitalize; */
}

.back-btn {
  font: normal normal bold 13px/51px Quicksand;
  letter-spacing: 0px;
  color: #8174B2 !important;
  margin-bottom: 40px;
  display: inline-block;
}

.back-btn i {
  float: left;
  margin-top: -1px;
  margin-right: 6px;
}

.soical-links a {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E2E7E7;
  border-radius: 35px;
  display: block;
  text-align: center;
  padding: 15px;
  color: #121B2A;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 24px;
  position: relative;
  text-decoration: none;
}


.soical-links a span {
  display: inline-block;
  width: 28px;
  height: 28px;
  text-align: center;
  position: absolute;
  left: 15px;
}

.verify-btn {
  position: absolute;
  z-index: 2;
  top: 18px;
  right: 15px;
  color: #BE8CB2;
  font-weight: 600;
  font-size: 15px;
}

.verify-btn a {
  text-decoration: underline !important;
}

.verify-btn i {
  float: left;
  width: 18px;
  margin-top: -2px;
  margin-right: 5px;
  display: inline-block;
}

.soical-links a span.email {
  top: 11px;
}

.soical-links a span.email img {
  width: 22px;
}

.soical-links a span.phone {
  top: 15px;
}

.soical-links a span.phone img {
  width: 13px;
}

.soical-links a span.apple {
  top: 11px;
}

.soical-links a span.apple img {
  width: 22px;
}

.soical-links a span.fb {
  top: 15px;
}

.soical-links a span.fb img {
  width: 22px;
}

.slider-tx {
  letter-spacing: 1.1px;
  color: #FFFFFF;
  opacity: 1;
  position: absolute;
  bottom: 50px;
  left: 130px;
  right: 130px;
  z-index: 2;
  font-size: 18px;
  line-height: 35px;
  width: 550px;
  margin: 0 auto;
}

.free a {
  text-decoration: underline;
}

/***********************/

.forgotpswd {
  text-align: right;
}

.forgotpswd a {

  letter-spacing: 0.14px;
  color: #e88500;
  text-decoration: none;
}

body {
  font-family: 'Quicksand', sans-serif;
  color: #1b1c1d;
  margin: 0;
}

.main_content {
  margin-top: -91px;
  display: flex;

}

.main_content .left-side {
  background: #E1EDF7 url('assets/images/img_banner_buki.png') no-repeat padding-box;
  opacity: 1;
  width: 50%;
  background-size: cover;
  min-height: 100vh;
  background-position: center;
  position: relative;

}

.main_content .left-side::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: url('assets/images/bg_text.svg') no-repeat padding-box;
  background-size: cover;
  min-height: 100vh;
  background-position: center;


}


.main_content .container {
  max-width: 500px;
}

.main_content .container.profile-section {
  max-width: 700px;
}

.main_content .container.business-section {
  max-width: 1060px;
}

.container_internal {
  margin-top: 100px;
}

/* ============ header css  ========== */
.outer-header.fixed-header.innerHeader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

}

.main_content .title .header,
.modal-header .title .header,
.page-content .title .header {
  font: normal normal bold 24px/24px Quicksand;
  letter-spacing: 0.56px;
  color: #1b1c1d;
  margin: 0;

}

.page-content .title .header {
  font: normal normal medium 18px/42px Quicksand;
  letter-spacing: 0px;
  color: #111111;
}
.min-menu{
  display: none;
}

.modal-subHeading {
  position: relative;
  letter-spacing: 0.48px;
  font-weight: 500;
  font-size: 14px;
  padding: 16px;
}

.modal-subHeading p {
  margin-bottom: 0px;
}

.modal-subHeading::after {
  content: '';
  background: transparent linear-gradient(259deg, var(--unnamed-color-b6c678) 0%, #FFB380 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(259deg, #B6C678 0%, #FFB380 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.14;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.modal-opens .mapOuter {
  display: block !important;
}

.title .header.sub-heading {
  font: normal normal bold 19px/35px Quicksand;
}

.main_content .title .smalls,
.modal-header .title .smalls {
  letter-spacing: 0.54px;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  color: #5A5A5A;
}

.main_content .sub_head {
  font: normal normal 500 14px Quicksand;
  color: #4E4E4E;
  font-weight: 500;
}

.main_content .sub_head span {
  color: #2b3991;
}

.modal-header {
  border-bottom: 0px;
  padding: 30px 30px 10px;
}

.modal-body {
  padding: 0px 30px 30px;
}


.teamDetail-popup .modal-body ul li {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

/* ================= sortby popup ====== */
.modal-body ul.sortby {
  padding: 0;
  margin: 0;
}

.modal-body ul.sortby li {
  display: block;
  margin-bottom: 0px;
}

.modal-body ul.sortby li a {
  font: normal normal 600 18px/35px Quicksand;
  letter-spacing: 0px;
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  color: #111111;
  border-top: 1px solid #dee2e6;
}

/* ================= sortby popup ====== */

.main_content .right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 100px 40px 40px;
}

.modal-content {
  border: 0px;
  border-radius: 32px;
  outline: 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

.modal-dialog {
  max-width: 560px;
}

.closeButton {
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
}

.modal-opens {
  overflow: hidden;
}

.modal-dialog.sucessPopup {
  max-width: 280px;
}

.modal-dialog.sucessPopup p {
  font-size: 15px;
  font-weight: 500;

  letter-spacing: 0.64px;
}

.modal-dialog.sucessPopup.sucess {
  max-width: 340px;
}

.title .modal-subHeading {
  position: relative;
  letter-spacing: 0.48px;
  font-weight: 500;
  font-size: 13px;
  padding: 7px 10px;
  border-radius: 0px;
  display: inline-block;
}

.title .modal-subHeading::after {
  border-radius: 2px
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.00) !important;
}

/* buttons */
.btn-common {
  max-width: 280px;
  padding: 17px 35px;
}

.button-primary {
  border: none;
  color: #fff !important;
  background: #8174B2 0% 0% no-repeat padding-box;

}

.button-secondary {
  border: none;
  color: #A5A7B7;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #D3D4DC;
  opacity: 0.3;
}

.fillBtn {
  opacity: 1 !important;
}

.btn {
  height: 56px;
  border-radius: 32px;
  text-align: center;
  font: normal normal 600 16px Quicksand;
  letter-spacing: 0.54px;
  width: 100%;
  outline: none;
  box-shadow: none !important;
  letter-spacing: 0.72px;
}

.register_now {
  font: normal normal medium 14px/65px Quicksand;
  text-align: center;
}

.register_now a {
  color: #e88500;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.back_button {
  text-decoration: none;
}

.back_button span {
  font: normal normal 500 13px/51px Quicksand;
  letter-spacing: 0px;
  color: #8174B2;
  opacity: 1;
  margin-left: 5px;
  cursor: pointer;
}

.showPass {
  position: absolute;
  right: 14px;
  top: 16px;
  cursor: pointer;
}

.upload_section {
  padding: 30px 0px 15px;
}

.upload_section .up_opt {
  width: 203px;
  height: 148px;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border: 1px dashed #BFBFBF;
  border-radius: 10px;
  border-radius: 10px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-right: 20px;
  text-align: center;
  margin-bottom: 15px;
}

.upload_section .up_opt img {
  max-width: 100%;
}



.upload_section .up_opt input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 2;
  cursor: pointer;
  background: transparent;
  opacity: 0;
}

.upload_section .up_opt span {
  display: block;
}

.upload_section .up_opt p {
  letter-spacing: 0.42px;
  color: #5A5A5A;
  opacity: 0.72;
  font-size: 14px;
  font-weight: 600;
  padding: 35px 35px;
}

.uploadClose {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.upload_section .up_opt.outerhide {
  border: 0px
}


/*  common use */
.text-center {
  text-align: center;
}

.full_lenght_right .container {
  width: calc(100% - 100px);
}

.d-flex {
  display: flex !important;
}

.field_head {
  margin-top: 10px;
  font: normal normal 500 18px Quicksand;
  letter-spacing: 0.61px;
  font-size: 16px;
  color: #1b1c1d;
}

.field_value {
  font: normal normal normal 14px Quicksand;
  letter-spacing: 0.48px;
  color: #4E4E4E;
}

.option-box {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #EFEFEF;
  border-radius: 12px;
  padding: 18px 25px;
  position: relative;
  width: calc(50% - 15px);
}

.filter .notice-label {
  width: 5px;
  height: 5px;
  position: absolute;
  top: 7px;
  right: 12px;
  border-radius: 50%;
  background-color: #8174B2;
}

.active_option {
  background: #FFF9F0 0% 0% no-repeat padding-box;
  border: 1px solid #e88500;
}

/* radio btn */
.radio {
  position: absolute;
  top: 15px;
  right: 15px;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

input[type="radio"]+label,
input[type="checkbox"]+label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  position: relative;
  width: 20px;
  cursor: pointer;
}

.ngx-slider {
  width: calc(100% - 80px) !important;
  margin-left: 35px !important;
  margin: 55px 45px 35px 35px !important;

}

.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value {
  position: absolute;
  top: -55px;
  left: -42px !important;
  font-weight: 600;
  font-size: 22px;
  color: #000;


}

.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value:After {
  content: 'Km';
}

.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit.ngx-slider-floor {
  position: absolute;
  top: -9px;
  left: -40px !important;
  font-weight: 500;
  color: #000;
  font-size: 14px;
  opacity: 1 !important;
  visibility: inherit !important;

}

.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit.ngx-slider-floor:after {
  content: 'Km';

}

.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit.ngx-slider-ceil {
  position: absolute;
  top: -9px;
  opacity: 1 !important;
  left: inherit !important;
  right: -50px !important;
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit.ngx-slider-ceil:after {
  content: 'Km';
}

/* input[type="radio"]:checked+label,
input[type="checkbox"]:checked+label {
  border: 1px solid #e88500;
} */

input[type="radio"]:checked+label:after {
  border: solid #e88500;
  border-width: 0 2px 2px 0;
  content: "";
  display: block;
  height: 8px;
  left: 7px;
  position: absolute;
  top: 4px;
  transform: rotate(45deg);
  width: 4px;
}

ul.button-wrap li input[type="radio"]:checked+label:after {
  display: none;
}

.ngx-slider-left-out-selection .ngx-slider-selection-bar .ngx-slider-selection-bar {
  background: #2ecb6f !important;
}

.ngx-slider-selection {
  background: #be8cb2 !important;
}

.ngx-slider .ngx-slider-bar {
  left: 0;
  width: 100%;
  height: 6px !important;
}

.ngx-slider .ngx-slider-pointer {
  cursor: pointer;
  width: 24px !important;
  height: 24px !important;
  top: -10px !important;
  background-color: #be8cb2 !important;
}


.ngx-slider .ngx-slider-pointer:after {
  display: none !important;
}

.ngx-slider-selection-bar {
  overflow: inherit !important;
  visibility: inherit !important;
}

/* dropedown */
.dropdown-menu.show {
  width: 228px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  border: 0px;
  padding: 0px;
  top: 0px;
}

.header-right .dropdown-menu.show {
  padding: 10px !important;
  top: 17px !important;
  right: 0px !important;
  left: auto !important;
}

.dropdown-menu.show ul {
  list-style: none;
  padding: 0;
}

.dropdown-menu.show li {
  padding: 10px 15px;

}

.dropdown-menu.show .features-menu li a {
  padding: 15px 15px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #111111;
  display: block;
  border-radius: 6px;
  text-align: center;
}

.dropdown-menu.show .features-menu li a:hover {
  color: #AC78A4;
  background: #F5EFF4;

}

.dropdown-menu.show .features-menu li {
  padding: 0px;
  border-radius: 6px;
}


.dropdown-menu.show li:hover {
  background-color: #ededed;
}


.notification-bing .notice-label {
  width: 5px;
  height: 5px;
  position: absolute;
  top: 5px;
  right: 10px;
  border-radius: 50%;
  background-color: #ee6767;
}

.header .dropdown-user span {
  letter-spacing: 0.32px;
  color: #111111;
  font-size: 16px;
  font-weight: 500;
}

.dropdown-menu.show ul li label,
.iti__country-name,
.iti__dial-code {
  padding: 0px 0;
  text-align: left;
  font: normal normal normal 14px Quicksand;
  letter-spacing: 0px;
  color: #1B1C1D;
  cursor: pointer;
  margin-bottom: 0px;
  font-size: 14px;
  white-space: unset;
}

.dropdown-item {
  padding: 0px;
  color: #292929;
  font-size: 15px;
  font-weight: 500;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: inherit;
}

.table-box .grlistDropDwon.dropdown-menu.show {
  border: transparent;
  border-radius: 10px;
  width: 230px;
  box-shadow: 0 1px 15px rgba(32, 33, 36, .28);
  position: absolute !important;
  left: -100px !important;
  top: -40px !important;


}

.grlistDropDwon.threedot.dropdown-menu.show .autoComplete li {
  padding: 7px 20px;
  border-bottom: 1px solid #f1f1f1;
}


.dropdown-menu {
  padding: 0px;
}

.closeSearch {
  background-size: 9px auto;
  width: 18px;
  height: 27px;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 18px;
  opacity: 0.5;
  left: 20px;
}

.closeSearch.search {
  background: url(assets/images/Search.svg) no-repeat 98% 4px;
  background-size: 16px auto;
  opacity: 1;
}

.searchBox {
  background: #F5F6F8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin: 10px;
}

#country-search-box,
.searchBox input[type="text"] {
  border: none !important;
  height: 40px;
  background: #F5F6F8 0% 0% no-repeat padding-box;
  padding-left: 40px;
}

.selectItem {
  line-height: 60px !important;
  font: normal normal 400 16px Quicksand;
  color: #959494;
}

.form-control.date-icon {
  background: url(assets/images/ic_dropdown.svg) no-repeat calc(100% - 10px) center / 11px;
}

.dropdown.show .date-icon {
  background: url(assets/images/rev_dropdown.svg) no-repeat calc(100% - 10px) center / 11px;
}

.show .down-icon {
  transform: rotate(180deg);
}

.sub_lable {
  font: normal normal normal 12px Quicksand;
  letter-spacing: 0.24px;
  color: #4E4E4E;
}

/* Calculate popup css  */

.calculate-popup {
  max-width: 50%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  min-height: 100%;
  background: #fff;
}

.calculate-popup .modal-content {
  padding: 30px 80px !important;
  box-shadow: none !important;
}


.calculate-popup .modal-content .dropdown-menu {
  border: 1px solid #EFEFEF;
  border-right: transparent;
}

.calculate-popup .modal-content .whtappliesBtn {
  margin-bottom: 15px;
}

.calculate-popup .modal-content input#inlineFormInputGroup {
  border: 1px solid #EFEFEF;
  border-left: transparent;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.calculate-popup .modal-content .calculateBtn {
  margin-top: 15px;
}

.calculate-popup .form-control {
  height: 45px !important;
}

.calculate-popup .whiteBoxIddetails .ukSingaporeBtn {
  padding: 5px 10px !important;
  display: block;
  text-align: left;
}


.modal-confirm .modal-header {
  padding: 0px !important;
}

.modal-confirm .modal-body {
  padding: 0px !important;
}

/* end */



.option_head {
  text-align: left;
  font: normal normal 600 22px Quicksand;
  letter-spacing: 0.75px;
  color: #1b1c1d;
  margin-top: 50px;
}

.option_margin {
  margin-right: 30px;
}

.cnt_btn {
  width: calc(50% - 15px);
  float: right;
  margin-top: 50px;
}

.full_width .container {
  width: 100%;
}

.full_width_internal .container {
  width: 100%;
  padding: 50px 100px 0 100px;
}

.heading_box {
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
}

.plans_box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  justify-content: center;
}

.plans_box .plan_box {
  width: 300px;
  min-height: 430px;
  background: #E8F0F8 0% 0% no-repeat padding-box;
  border-radius: 18px;
  margin: 0 20px;
  padding: 25px 20px 10px;
  margin-bottom: 30px;
}

.tag {
  border-radius: 19px;
  padding: 9px 20px;
  color: #fff !important;
  font: normal normal 500 12px Quicksand;
}

.plan_box .tab_head {
  font: normal normal 600 18px Quicksand;
  letter-spacing: 0.2px;
  color: #1b1c1d;
  padding: 20px 0;
}

.border-bottom {
  border-bottom: 1px solid #D3D4DC;
}


.border-bottom.dashed {
  border-bottom: 1px dashed #E1E7ED !important;
}

.plans_box ul,
.detail_box ul {
  padding: 0;
  list-style: none;
}

.plans_box ul li,
.detail_box ul li {
  padding: 8px 0;
  font: normal normal 500 12px Quicksand;
  background: transparent url('/assets/images/ic_tick.svg') 0% 0% no-repeat padding-box;
  background-position: left 14px;
  padding-left: 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
}

a {
  cursor: pointer;
}

.back_orange {
  background: #EAB063 0% 0% no-repeat padding-box;
}

.back_neon {
  background: #39C3CB 0% 0% no-repeat padding-box;
}

.back_purple {
  background: #9881DD 0% 0% no-repeat padding-box;
}

.back_red {
  background: #FA6D76 0% 0% no-repeat padding-box;
}

.head_text {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;
}

.amount {
  font: normal normal 600 22px Quicksand;
  letter-spacing: 0.24px;
  color: #1b1c1d;
}

.years {
  font: normal normal normal 12px Quicksand;
  letter-spacing: 0.14px;
  color: #7c7a7a;
}

.plan_box .desc {
  font: normal normal 400 12px Quicksand;
  letter-spacing: 0.48px;
  color: #1b1c1d;
}

.plan_box .send_head {
  text-align: left;
  font: normal normal 600 12px Quicksand;
  letter-spacing: 0.48px;
  color: #1b1c1d;
  margin-top: 50px;
}

.plan_box .mail_to {
  font: normal normal 500 12px Quicksand;
  letter-spacing: 0.48px;
  color: #2b3991;
}

.summary .container {
  max-width: 800px !important;
}

.summary .container .heading_box {
  text-align: left !important;
  max-width: 100% !important;
}

.summary_box {
  background: #F4F8FC 0% 0% no-repeat padding-box;
  border-radius: 18px;
  width: 100%;
  margin-top: 30px;
  padding: 30px;
}

.summary_box .summary_head {
  font: normal normal 600 18px Quicksand;
  letter-spacing: 0.68px;
  color: #1b1c1d;
}

.summary_box .detail_box {
  background: #E8F0F8 0% 0% no-repeat padding-box;
  border-radius: 18px;
  padding: 20px;
}

.summary_box .detail_box .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}

.detail_box ul {
  padding-top: 15px;
  margin-bottom: 0;
}

.white_box {
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  padding: 20px;
  margin: 10px 0;
}

.white_box p {
  font: normal normal 500 14px Quicksand;
  margin: 0;
  letter-spacing: 0.54px;
  color: #1b1c1d;
}

.calculation {
  color: #4E4E4E;
  font: normal normal 400 14px Quicksand;
}

.calculation_total {
  color: #1b1c1d;
  font: normal normal 500 14px Quicksand;
}

.calculation .value,
.calculation_total .value {
  text-align: right;
}

.summary .field,
.summary .value {
  padding: 5px 0;
}

.summary .count_btn {
  background: #2b3991 0% 0% no-repeat padding-box;
  border: 1px solid #2b3991;
  border-radius: 3px;
  padding: 6px 15px;
  font: normal normal 600 14px/21px Quicksand;
  color: #fff !important;
  margin: 0 10px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.main_header input:focus+.slider {
  box-shadow: 0 0 1px #328337;
}

.main_header input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.main_header input:checked+.slider {
  background-color: #328337;
}

.switch {
  display: inline-block;
  width: 52px;
  height: 25px;
  position: absolute;
  right: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* internal compenent */
.main {
  width: calc(100% - 280px);
  padding: 100px 30px;
  margin-left: 280px;
}

.main .head {
  font: normal normal 600 28px Quicksand;
  color: #1b1c1d;
}

.pointer {
  cursor: pointer;
}

.table_content .searchBox {
  position: relative;
  max-width: 400px;
  background: #F5F6F8 0% 0% no-repeat padding-box;
  margin: 10px 0 !important;
}

.table_content .searchBox input::placeholder {
  font: normal normal 400 14px Quicksand;
  letter-spacing: 0px;
  color: #b2b2b2 !important;
}

.table_content .closeSearch {
  top: 8px;
  left: 13px;
}

.table_content {
  margin-top: 30px;
}

.table_head {
  font: normal normal 600 18px Quicksand;
  letter-spacing: 0.68px;
  color: #1b1c1d;
}

.table-box {
  margin-top: 30px;
}

.scroller {
  margin-bottom: 15px;
  overflow: auto;
}

.scroller {
  overflow-y: auto;
}

.scroller::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #E9E9E9;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: #AC78A4;
}

.table-box table {
  width: 100%;
}


.table-box table thead {
  border-bottom: 5px solid white;
}

.table-box table thead th {
  background: #F4F8FC 0% 0% no-repeat padding-box;
  padding: 10px;
  font: normal normal 600 13px Quicksand;
  letter-spacing: 0.3px;
  color: #1b1c1d;
  height: 80px;
  vertical-align: text-bottom;
}

.table-box table tbody tr {
  font: normal normal 400 12px Quicksand;
  letter-spacing: 0.3px;
  color: #1b1c1d;
  height: 80px;
  border-bottom: 5px solid white;
}

.table-box table tbody tr td {
  padding: 10px;
  min-width: 100px;
}

.table-box table thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-box table thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-box table tbody td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-box table tbody td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table_row_red {
  background: #F9EEEE;
}

.table_row_yellow {
  background: #FAF7E5;
}

.table_row_green {
  background: #EDFAF3;
}

.table_row_grey {
  background: #F8F9FA;
}

.radio_box {
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  position: relative;
  min-height: 50px;
  margin: 20px 0;
}

.radio_box .radio_label {
  text-align: left;
  font: normal normal 400 15px Quicksand;
  letter-spacing: 0px;
  color: #4E4E4E;
  padding: 12px 35px 12px 15px;
}

.question_box {
  word-wrap: break-word;
}

.info {
  background: url(/assets/images/ic_info.svg) 0% 0% no-repeat padding-box;
  height: 25px;
  width: 25px;
}

p.profileName {
  word-wrap: break-word;
}

.page-sidebar ul li.active img.colorize-color {
  stroke: #fff;
}

/* body.sidebar-mini */

body.sidebar-mini .side-menu>li>a>.nav-label p {
  display: none !important;
}



body.sidebar-mini .page-sidebar {
  width: 101px !important;
  padding-top: 0px;
}

body.sidebar-mini .content-wrapper {
  margin-left: 100px;
}

body.sidebar-mini .main {
  width: calc(100% - 100px) !important;
  margin-left: 100px;
}

body.sidebar-mini .innerHeader .header-right {
  width: calc(100% - 100px) !important;
}

body.sidebar-mini .outer-logo.ex {
  width: 101px;
  border-bottom: 1px solid #e7e7e7;
}

.ng-scrollbar-thumb {
  background-color: #AC78A4 !important;
  border-radius: 1px !important;
}

.ng-scrollbar-wrapper>scrollbar-y.scrollbar-control>.ng-scrollbar-track {
  background: #E9E9E9 0% 0% no-repeat padding-box !important;
}

body.sidebar-mini .outer-logo.ex img {
  max-width: 50px;
}

body.sidebar-mini .side-menu li i.sidebar-item-icon.ic_dashboard {
  margin-right: 0px !important;
  text-align: left;
}



body.sidebar-mini span.icon-bar.line-first {
  width: 20px;
}

body.sidebar-mini span.icon-bar.line-second {
  width: 20px;
}

body.sidebar-mini span.icon-bar.line-third {
  width: 20px;
  margin-left: 0px;
}


/* ======  whatsPopup start ======== */

i.ic_red {
  width: 12px;
  height: 12px;
  background: #F31B26;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  margin-right: 5px;
}

i.ic_yellow {
  width: 12px;
  height: 12px;
  background: #F2B626;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  margin-right: 5px;
}

i.ic_green {
  width: 12px;
  height: 12px;
  background: #15AD55;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  margin-right: 5px;
}

.whatsPopup .modal-content {
  border-radius: 24px;
}

.whatsPopup .modal-content h4.modal-title {
  font-size: 20px;
  margin-bottom: 25px;
  text-align: center !important;
  color: #1B1C1D;
  font-weight: 600;
}

.whatsPopup .modal-content h6 {
  font-size: 16px;
  margin: 10px 0px;
  color: #1B1C1D;
  font-weight: 600;
}

.whatsPopup .modal-content .modal-header {
  padding: 30px 30px 15px;
}

.whatsPopup .modal-content p {
  font-size: 14px;
  font-weight: 600;
  color: #1B1C1D;

}

.whatsPopup .modal-content ul {
  margin: 0px;
  padding: 0px !important;
}


.calculate-popup.modal-confirm.modal-dialog.modal-dialog-centered.w-5 {
  align-items: unset !important;
}

.whatsPopup .modal-content ul li {
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 20px;
  position: relative;
  padding-left: 25px;
}

.whatsPopup .modal-content ul li i.ic_check {
  position: absolute;
  top: 0px;
  left: 5px;

}

.whatsPopup .modal-content .modal-body {
  padding: 40px;
}

/* star css */

.starWrap span.bs-rating-star {
  font-size: 40px;
  display: inline-block;
  line-height: 40px;
  margin-right: 8px;
  color: #FFAE15;
  opacity: 0.2;
}

.starWrap span.bs-rating-star.active {
  color: #FFAE15;
  opacity: 1;
}

/* star css */
.calendar-box .bs-datepicker {
  background: #F8F7FA 0% 0% no-repeat padding-box;
  box-shadow: none;
}




/* ======  whatsPopup start end ======== */

/* share Popup css  */
.sharePopup .modal-content {
  border-radius: 24px;
}

.sharePopup .ic_cross {
  position: absolute;
  top: -10px !important;
  right: 0px !important;
  display: inline-block;
  vertical-align: middle;
}

.sharePopup .modal-content {
  padding: 40px !important;
  border-radius: 20px !important;
}

.sharePopup h4.text-center {
  margin-bottom: 24px;
}

.sharePopup .modal-body p.text-center {
  font-size: 14px;
}

.sharePopup input.form-control {
  height: 50px;
}

.sharePopup input.form-control.pr-5 {
  font-size: 14px;
}


/*   quitPopup    */
.question-mark {
  max-width: 120px;
}

.quitPopup .modal-content {
  padding: 40px !important;
  border-radius: 24px !important;
}

.quitPopup .modal-content .modal-body {
  padding: 0px !important;
}

.btn.active {
  border: 1px solid #B2B2B2;
  border-radius: 12px;
  color: #E61313;
}

.modal-body h6 {
  font-size: 20px;
  margin: 30px 0px 10px;
}


.modal-dialog.teamDetail-popup .modal-body h6 {
  margin: 0px;
}

.modal-dialog.teamDetail-popup .modal-body h6:last-child {
  text-align: right;
  font-weight: 500;
}

/* no_result_found css */

.no_result_found {
  max-width: 350px;
  margin: 40px auto;
  text-align: center;
  padding: 20px;
}

.no_result_found img {
  max-width: 250px;
  margin-bottom: 10px;
}

.no_result_found p {
  color: #3E3F40;
}

/* no_result_found css end */


/* ====== Add User Popup ======== */


.sideBar-modal {
  max-width: 60%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  min-height: 100%;
  background: #fff;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  -webkit-transform: translate(25%, 0%);
  transform: translate(25%, 0);
  -webkit-transform: translate(0, 0) !important;
  transform: translate(0, 0);
}

.sideBar-modal .modal-content {
  border-bottom: transparent;
}

.modal.fade.show .modal-dialog.sideBar-modal {
  transform: translate(0, 0);
}

.modal.fade .modal-dialog.sideBar-modal {
  transition: transform .3s ease-out;
  transform: translate(25%, 0);
}

.modal-lg.sideBar-modal .modal-header {
  padding: 30px 80px 0px 80px;
  border-bottom: 0px;
}

.modal-lg.sideBar-modal .modal-body .form-group label {
  color: #1B1C1D;
  margin-bottom: 10px;
}

.modal-lg.sideBar-modal .modal-body .form-group .form-control {
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  color: #3E3F40 !important;
  height: 50px;
  padding: 0px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.modal-lg.sideBar-modal .form-group .tagline {
  display: inline-block;
  font-size: 11px;
  margin: 0px 0px 10px 10px;
}

.modal-lg.sideBar-modal .modal-body {
  padding: 10px 80px 30px 80px;
}

.modal-lg.sideBar-modal h4.modal-title {
  font-size: 24px;
}

.modal-lg.sideBar-modal h5.sub-title {
  font-size: 20px;
  margin-bottom: 5px;
}

.myLicensewrap {
  margin-top: 30px;
}

.license-option {
  width: 100%;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  padding: 18px 25px;
  position: relative;
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;

}

.license-option li {
  display: inline-block;
  vertical-align: middle;
}

.license-option li .radio {
  top: 25px;
}

.license-option li span {
  display: block;
  font-size: 12px;
}

.license-option li strong {
  font-size: 14px;
  display: block
}

.license-option.active_option {
  background: #FFF9F0 0% 0% no-repeat padding-box;
  border: 1px solid #e88500;
}

/* button bottom space only use class name */
.bottom_space {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.bottom_btn {
  margin: auto;
}

/* button bottom space only use class name */
.editrolepopup.modal-dialog.modal-lg.sideBar-modal {
  width: 40% !important;
}

.editrolepopup.modal-dialog.modal-lg.sideBar-modal .form-group.bottom_btn button {
  width: 100%;
}

.modal-dialog.modal-lg.reassignlicensePopup.sideBar-modal {
  width: 40%;
}


.main {
  position: relative;
}


/* ======== pagination-wrapper css ============ */

.pagination-wrapper {
  position: absolute;
  bottom: -20px;
  left: 0px;
  background: #fff;
  padding: 0px 40px;
  width: 100%;
}

.ngx-pagination {
  margin: 0;
  padding: 0px;
}

.ngx-pagination .disabled {
  padding: 10px 27px !important;
  cursor: default;
  border: 1px solid #DAD8D8;
  border-radius: 20px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #4C4C4B;
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
  text-decoration: none;
  color: #fff;
  background-color: #8174B2 !important;
  border-color: #8174B2 !important;
  border-radius: 20px;
}


.pagination-wrapper .pagination-previous a:before {
  display: none !important;
}

.pagination-wrapper .pagination-previous.disabled:before {
  display: none !important;
}

.pagination-previous a:before {
  display: none !important;
}

.pagination-next a:after {
  display: none !important;
}

.ngx-pagination .current {
  padding: 10px 27px !important;
  background: #8174B2 !important;
  border: 1px solid #8174B2;
  border-radius: 20px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.ngx-pagination a {
  padding: 10px 27px !important;
  border: 1px solid #DAD8D8;
  border-radius: 20px !important;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
}

.pagination-next a {
  padding: 10px 27px !important;
  border: 1px solid #DAD8D8;
  border-radius: 20px !important;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
}

.ngx-pagination li {
  margin-right: 5px !important;
}

.pagination-previous a {
  border: transparent !important;
}

.buyLicence.btn {
  height: 50px !important;
}


.white_box ul .li.totalprice p {
  font-weight: 600;
}


/* =============== pagination ====== */

.page-item.disabled .page-link {
  pointer-events: none;
  border: 1px solid #DAD8D8;
  border-radius: 20px;
  letter-spacing: 0px;
  color: #4C4C4B;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  width: auto;
}

li.paginate_button.page-item {
  margin-right: 10px;
}

.page-item.active .page-link {
  background: #8174B2 0% 0% no-repeat padding-box;
  border: 1px solid #8174B2;
  border-radius: 20px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.page-link {
  width: 40px;
  height: 40px;
  text-align: center;
  position: relative;
  background: #FFFFFF00 0% 0% no-repeat padding-box;
  border: 1px solid #C7C8C3;
  border-radius: 20px;
  letter-spacing: 0px;
  color: #4C4C4B;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}


.page-link:hover {
  z-index: 2;
  background: #8174B2 0% 0% no-repeat padding-box;
  border: 1px solid #8174B2;
  border-radius: 20px;
  letter-spacing: 0px;
  color: #FFFFFF;

}






.w-8 {
  min-width: 800px;
}

.iti {
  width: 100%;
}

.country-dropdown {
  max-width: 375px;
}

.search-container {
  padding: 5px;
}

.iti__country-list {
  max-height: 150px;
}

.dropdown-menu {
  max-height: 300px;
  overflow: auto;
}

.cancel {
  opacity: 1 !important;
}

.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 24px;
  border: none;
  text-align: center;
  font-size: 14px;
  box-shadow: 10px 10px 20px #00000029;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
  display: block;
}

.modal-confirm .blueText {
  color: #2b3991;
}

.modal-confirm .form-group label {
  text-align: left;
}

.modal-confirm .modal-header span img {
  padding-right: 5px;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 10px;
}

.modal-confirm .modal-body {
  color: #1b1c1d;
}

.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 10px;
}

.modal-confirm .modal-footer a {
  color: #999;
}

.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}

.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;

}

.content-wrapper {
  position: relative;
  background-color: #fff;
  margin-left: 275px;
  padding: 120px 40px 60px 40px;
  -webkit-transition: margin .2s ease-in-out;
  -o-transition: margin .2s ease-in-out;
  transition: margin .2s ease-in-out;
}

/*new*/

.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 32px;
  text-decoration: none;
  transition: all 0.4s;
  /* line-height: 22px; */
  border: none;
  margin: 0 5px;
}

.modal-confirm .btn-secondary {
  background: #c1c1c1;
}

.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}

.modal-confirm .btn-danger {
  background: #f15e5e;
}

.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}

.modal-confirm .trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.modal-confirm .modal-footer {
  display: block;
}

.modal-confirm button.delete_account {
  width: 100%;
  border: 1px solid #b2b2b2;
  display: block;
  padding: 10px;
  width: 100%;
  background: transparent;
  color: #E61313;
  border-radius: 8px;
  outline: none;
}

.modal-confirm .cancel_btn {
  border: 1px solid transparent;
  display: block;
  padding: 10px;
  width: 100%;
  background: transparent;
  color: #A5A7B7;
  outline: none;
}

.iti__selected-flag {
  max-width: 50px;
}

.iti.separate-dial-code input {
  padding-left: 75px !important;
}

.No_found {
  padding-left: 12px;
}

.dropdown-menu.show ul li label,
.iti__country-name,
.iti__dial-code,
.selectItem {
  text-transform: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.text-danger {
  text-align: left;
  font-size: 13px;
  font-weight: 500;
}

.img_up {
  max-width: 80px;
  cursor: pointer;
}

.dropdown-menu.country-dropdown.show {
  width: 300px !important;
}

.iti__flag {
  display: none;
}

input#country-search-box {
  padding-left: 15px !important;
}

.dropdown-menu.show ul .iti__flag {
  display: block
}



.tooltip-inner {
  background-color: #fff;
  color: black;
}

.No_found_data {
  text-align: center;
  margin-top: 40px;
  width: 100%;
}

.btn.text-blue {
  letter-spacing: 0.54px;
  color: #2B3991;
  text-transform: capitalize;
  border: 1px solid #2B3991;
  border-radius: 8px;
}

.modal-body.infopopup h6 {
  font-weight: 600;
}

.modal-body.infopopup p {
  font-size: 14px;
}

.Notification_msg p {
  margin-bottom: 5px;
}

.Notification_msg {
  background: rgb(215 221 255) !important;
}

.Notification_msg p,
.Notification_msg h4 {
  font-size: 15px;
  color: #333;
}

.pulse1 {
  display: block;
  position: absolute;
  top: 5px;
  left: 20px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #2b3991;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(50, 68, 179, 0.9);
  animation: pulse1 2s infinite;
}



.pulse1:hover {
  animation: none;
}

.save-exit {
  font: normal normal bold 15px/23px Quicksand;
  letter-spacing: 0.72px;
  color: #8174B2 !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #909090;
  border-radius: 32px;
  padding: 8px 20px;
  text-decoration: none;
  display: inline-block;
}

.language {
  margin-left: auto;
}

.progress {
  height: 4px;
  border-radius: 0px 4px 4px 0px;
  background: #F2E9F0 0% 0% no-repeat padding-box;
}

.progress-bar {
  background: #BE8CB2 0% 0% no-repeat padding-box;
}


@-webkit-keyframes pulse1 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(9, 173, 149, 0.9);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(9, 173, 149, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(9, 173, 149, 0);
  }
}

@keyframes pulse1 {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(9, 173, 149, 0.9);
    box-shadow: 0 0 0 0 rgba(9, 173, 149, 0.7);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(9, 173, 149, 0);
    box-shadow: 0 0 0 10px rgba(9, 173, 149, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(9, 173, 149, 0);
    box-shadow: 0 0 0 0 rgba(9, 173, 149, 0);
  }
}

.email-check {
  max-width: 440px;
  text-align: center;
}

.email-check p {
  font-weight: 500;
  font-size: 15px;
}

.email-check p span {
  font-weight: 600;
  color: #8174B2
}

.image-box {
  display: block;
}

.swal-Alerts {
  max-width: 325px;
  border-radius: 15px;
  background: #fff;
}

.swal-Alerts .swal2-title {
  display: block;
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin-top: 10px;
  margin-bottom: 8px;
}

.swal-Alerts .swal2-html-container {
  display: block;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 500;
}

.swal-Alerts .swal2-confirm {
  display: inline-block;
  width: fit-content;
  min-width: 112px;
  border-radius: 32px;
  margin-bottom: 5px;
  background: no-repeat;
  background: #F4F2FF 0% 0% no-repeat padding-box;
  border: 1px solid #8174B2;
  color: #8373A4;
  font-weight: 600;
}

.swal-Alerts .swal2-confirm:hover {
  background: none;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.swal2-html-container {
  margin: 1em 1.25em 0.3em
}

:where(.swal2-styled).swal2-confirm:focus {
  box-shadow: inherit !important;
  outline: none;
  box-shadow: 0px 0px 0px !important;
}

:where(.swal2-actions):not(.swal2-loading) .swal2-styled:active {
  background-image: none !important
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
    transform: translate3d(0, 0, 0);
  }
}


/* ========= */
.page-heading {
  display: flex;
}

.flexbox {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.page-heading .page-title {
  font-size: 22px;
  margin: 15px 0 15px 0;
  display: inline-block;
  font: normal normal bold 26px/42px Quicksand;
  letter-spacing: 0px;
  color: #111111;
  margin: 0px;
}

.right-panel {
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}

a.add-btn {
  background: #8174B2 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #00000002;
  border-radius: 25px;
  font: normal normal 600 14px/20px Quicksand;
  letter-spacing: 0px;
  color: #FFFFFF !important;
  padding: 13px 24px;
}

.modal-dialog.teamDetail-popup .modal-body h6 {
  width: 50%;
  font-weight: 600;
}

.modal-dialog.teamDetail-popup .modal-body h6 span i {
  display: inline-block;
  word-wrap: break-word;
  margin-left: 10px;
  margin-bottom: 10px;
}

.col-left {
  position: relative;
}

.col-left .input-icon {
  position: absolute;
  left: 17px;
  top: 7px;
}

.col-left .input-icon img {
  width: 15px;
}

a.filter {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #DAD8D8;
  border-radius: 25px;
  display: inline-block;
  padding: 9px 20px;
  letter-spacing: 0px;
  color: #111111;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  position: relative;
}

a.filter img {
  width: 15px;
  margin-left: 5px;
}

a.cross-btn {
  position: absolute;
  right: 2px;
  top: 3px;
  padding: 2px 7px;
  color: #726969;
}

a.cross-btn img {
  width: 22px;
}


/* ======= filter POPUPS ========== */

.filter-title {
  font: normal normal 600 18px/51px Quicksand;
  letter-spacing: 0px;
  color: #111111;
}

ul.button-wrap {
  margin: 0px;
  padding: 0px;
}

ul.button-wrap li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
}

ul.button-wrap li input[type="radio"]+label {
  width: 100%;
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E2E7E7;
  border-radius: 25px;
  font-size: 14px;
  padding: 10px 20px;
  letter-spacing: 0.91px;
  letter-spacing: 0.91px;
  color: #5A5A5A;
  margin-bottom: 20px;
}

ul.button-wrap li input[type="radio"]:checked+label {
  width: 100%;
  height: 100%;
  background: #F4F2FF 0% 0% no-repeat padding-box;
  border: 1px solid #8174B2;
  color: #5A5A5A;
}

ul.button-wrap li input {
  display: none !important;

}


.radio-wrap .radio {
  margin: 0;
  display: inline-block;
  margin-right: 20px;
}


.radio-wrap input[type=radio] {
  position: absolute;
  opacity: 0;
}

.radio-wrap input[type=radio]+.radio-label:before {
  content: "";
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: 1px;
  margin-right: 5px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

.radio-wrap input[type=radio]:checked+.radio-label:before {
  background-color: #8174B2;
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border: 1px solid #8174B2;
}

.radio-wrap input[type=radio]:focus+.radio-label:before {
  outline: none;
  border-color: #3197EE;
}

.radio-wrap input[type=radio]+.radio-label:empty:before {
  margin-right: 0;
}

.radio-wrap .radio label.radio-label {
  cursor: pointer;
}

.salary-wrap {
  padding: 30px 0px;
}

.salary-wrap p.sub-heading {
  font: normal normal bold 19px/35px Quicksand;
}

.salary-wrap .sub-heading {
  margin-bottom: 5px;
}

.salary-wrap span.salary-desc {
  font-size: 14px;
  letter-spacing: 0px;
  color: #5A5A5A;
  opacity: 1;
  display: inline-block;
}

.buttons {
  display: flex;
  text-align: center;
}

.apply-btn {
  width: auto;
  padding: 17px 50px;
  /* width: 49%; */
}

.apply-btn:hover {
  color: #fff !important;
}

.apply-btn {
  width: 49%;
}

.clear-btn {
  padding: 17px 50px;
  width: 49%;
  text-decoration: underline;
  font: normal normal bold 14px/18px Quicksand;
  letter-spacing: 0.28px;
  color: #9A9A9A;
}

.unfill-btn {
  border: 1px solid #DCDDDF;
  color: #909090 !important;
  background: transparent;
}

.modal-footer>:not(:last-child) {
  margin-right: 10px !important;
}


.modal-footer.buttons {
  padding: 0px 30px 30px;
}

.gradient-bg {
  position: relative;
  padding: 20px;
}

.gradient-bg strong {
  display: block;
  margin-bottom: 10px;
  font: normal normal bold 18px/23px Quicksand;
  letter-spacing: 0px;
  color: #111111;
}

.gradient-bg p {
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #111111;
}

.gradient-bg::after {
  content: '';
  background: transparent linear-gradient(259deg, var(--unnamed-color-b6c678) 0%, #FFB380 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(259deg, #B6C678 0%, #FFB380 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.14;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.deactivate-popup.modal-dialog .title.mb-4.mt-1 {
  text-align: center;
  margin: 0 auto;
}

.deactivate-popup.modal-dialog p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
}

.delete-popup.modal-dialog .title.mb-4.mt-1 {
  text-align: center;
  margin: 0 auto;
}

.delete-popup.modal-dialog p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
}

a.btn.button-primary.delete-btn {
  padding: 17px 0px !important;
}

a.btn.button-primary.unfill-btn.edit-btn {
  padding: 17px 0px !important;
}

/* modal-right */

.modal-dialog.modal-right {
  position: fixed;
  right: -600px;
  transition: opacity .3s linear, right .3s ease-out;
  margin: auto;
  max-width: 100%;
  width: 600px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
  transition: opacity .3s linear, right .3s ease-out;
}

.show .modal-dialog.modal-right {
  right: 0;
  transition: opacity .3s linear, right .3s ease-out;
}

.modal-dialog.modal-right .modal-content {
 
  height: 100%;
  border-radius: 0;
  border: none;
}

.modal-dialog.modal-right .modal-header {
  padding: 40px 28px 20px;
}

.modal-dialog.modal-right .modal-body {
  padding: 20px 28px;
  background: #F5F5F5;

}
.modal-dialog.modal-right.rightSide .modal-body{
  padding: 0px 0px 20px;
  background: #F5F5F5;

}



/* table common css  */

.table-page .table thead {
  background: #F5F5F5;
}

.table-page .table thead th {
  padding: 20px 20px;
  border-top: 1px solid transparent;
}

.table-page .table-bordered th {
  padding: 25px 20px;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.table-page .table-bordered td {
  padding: 0px;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}

.table-page .table-bordered td a {
  display: inline-block;
  padding: 20px 20px;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}

.table-page .table-striped tbody tr:nth-of-type(odd) {
  background: transparent;
}

.table-page .table-bordered td a span.pending {
  background: #F79B59 0% 0% no-repeat padding-box;
  border-radius: 28px;
  font: normal normal medium 14px/42px Quicksand;
  letter-spacing: 0px;
  display: inline-block;
  padding: 4px 15px;
  line-height: 22px;
  color: #FFFFFF;
}

.table-page .table-bordered td a span.delete {
  background: #EB7B7C 0% 0% no-repeat padding-box;
  border-radius: 28px;
  font: normal normal medium 14px/42px Quicksand;
  letter-spacing: 0px;
  display: inline-block;
  padding: 4px 15px;
  line-height: 22px;
  color: #FFFFFF;
}



.table-page .table-bordered td a span.active {
  background: #B2C66C 0% 0% no-repeat padding-box;
  border-radius: 28px;
  font: normal normal medium 14px/42px Quicksand;
  letter-spacing: 0px;
  display: inline-block;
  padding: 4px 15px;
  line-height: 22px;
  color: #FFFFFF;
}

.table-page .table-bordered td a {
  font: normal normal 600 16px/42px Quicksand;
  letter-spacing: 0px;
  color: #111111;
}


.table-page .table-bordered td a img {
  width: 35px;
  margin-right: 10px;
  height: 35px;
  border-radius: 50%;
}

.small-title {
  font: normal normal 700 18px Quicksand;
  letter-spacing: 0px;
  color: #182138;
}

.small-title .date {
  font: normal normal 600 12px Quicksand;
  letter-spacing: 0.24px;
  color: #3E3E3B;
  background: #FFF0F0 0% 0% no-repeat padding-box;
  border-radius: 16px;
  padding: 2px 10px;
}

.payment-details {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-details span {
  font: normal normal 600 20px Quicksand;
  letter-spacing: 0px;
  color: #111111;

}

.payment-details p {
  font: normal normal 600 16px Quicksand;
  letter-spacing: 0px;
  color: #2B2B2B;
  margin: 0;
}

.add-btn.transparent-btn {
  border: 1px solid #8174B2;
  border-radius: 25px;
  background-color: #fff;
  color: #8174B2 !important;
}

.payment-details img {
  margin-right: 10px;
}

/* end */

.modal-confirm.modal-dialog.modal-dialog-centered.reschedule {
  width: 100%;
  max-width: 700px;
}


/* FAQ's css */

.faq .faq-list li .card {
  border: transparent;
}

.faq .faq-list li .panel-heading.card-header.panel-enabled {
  padding: 20px;
  background: transparent linear-gradient(260deg, #F4F7EB 0%, #FFF4EC 100%) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  position: relative;
  border: transparent;
}

.faq .faq-list li .panel-title {
  padding-right: 20px;
}


.faq .faq-list li button.btn.btn-link {
  font: normal normal bold 20px/25px Quicksand;
  letter-spacing: 0.4px;
  color: #111111;
  text-align: left;
  padding: 0px;
  height: auto;
}

.faq .faq-list li button.btn.btn-link:hover {
  text-decoration: none;
}

.faq .faq-list li .panel-body.card-block.card-body {
  padding: 0px 20px 20px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  font-family: Quicksand;
  letter-spacing: 0.18px;
  color: #5A5A5A;
}

.faq .faq-list li .accordion-group.panel.card.panel-open .panel-heading.card-header.panel-enabled {
  background: #E9E6EF !important;
}

.faq .faq-list li accordion-group.panel.card.panel-open {
  background: #E9E6EF !important;
}

.faq .faq-list li accordion-group.panel.card.panel-open .panel-heading.card-header.panel-enabled {
  background: #E9E6EF !important;
}

.faq .faq-list li .panel-collapse.collapse.in.show .panel-body.card-block.card-body {
  background: #E9E6EF;
}


/* .faq .faq-list li .panel-body.card-block.card-body::after {
  content: '';
  background: #E9E6EF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 0.39;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
} */



.faq .faq-list li .accordion .panel-open .panel-default.card::after {
  transform: rotate(180deg);
  transition: .5s ease;
}

.faq .faq-list li .accordion .panel-default.card:after {
  width: 20px;
  height: 20px;
  content: "";
  background: url(assets/images/ic_dropdown2.svg);
  position: absolute;
  top: 25px;
  right: 20px;
  font-family: 'FontAwesome';
  transition: .5s ease;
  background-repeat: no-repeat;
}


/* select-user-form css  Appointments */
.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  display: none;
}

.select-user-form .multiselect-dropdown .dropdown-btn {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 55px;
  line-height: 36px !important;
  line-height: 1.42857143;
  color: #3E3F40 !important;
  border: none;
  border: 1px solid #BFBFBF;
  outline: none;
  border-radius: 10px !important;
  box-shadow: none;
  background: #fff 0% 0% no-repeat padding-box;
  padding: 8px 35px 8px 14px !important;
  font-size: 14px;
  font-weight: 500;
  resize: none;
}

.select-user-form input {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid #BFBFBF !important;
  padding: 10px 15px !important;
}



/* select-user-form css  Appointments end */

.internalnote-popup.modal-dialog textarea.internal_note {
  height: 100px;
}
.bs-datepicker-body span.custom-today-class{
  background:#e9edf0!important;
  border-radius: 50%;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid #8174B2!important;
  margin-right: 4px;
  margin-bottom: 4px;
  background: #8174B2!important;
  padding: 0px 5px;
  color: #fff;
  border-radius: 2px;
  float: left;
  background: #8174B2 0% 0% no-repeat padding-box !important;
  margin-top: 0px;
}
.multi .multiselect-dropdown .dropdown-btn{

  height: 50px!important;
  line-height: 30px !important;
}

/* ======= responsive css start ======== */

@media (max-width: 1400px) and (min-width: 1200px)  {
  a.btn-grey{min-width: 170px !important;}
}



@media(max-width:1400px) {
  a.btn.button-primary.delete-btn {
    padding: 9px 0px !important;
  }
  
  a.btn.button-primary.unfill-btn.edit-btn {
    padding: 9px 0px !important;
  }
  .main_content {
    margin-top: -61px;

  
  }
  .showPass {

    top: 9px;}

  .table thead th{
    font-size: 14px!important;
  }
  .table-bordered td a{
    font-size: 13px!important;
  }

  .content-wrapper {
    margin-left: 230px;
    padding: 80px 30px 40px 30px;
  }

  .back-btn {
    margin-bottom: 10px !important;
  }

  .steps {
    padding: 10px 15px !important;
    font-size: 12px !important;
  }

  .title .header.sub-heading i img {
    width: 18px !important;
  }

  .title .header.sub-heading {
    font-size: 18px !important;
    line-height: 25px;
    margin-bottom: 5px;
  }

  .main_content .right-side {
    padding: 85px 40px 40px;
  }

  .free {
    font-size: 14px;
  }

  .right-side .title.mb-4 {
    margin-bottom: 15px !important;
  }

  input[type="text"],input[type="number"],
  input[type="password"],
  input[type="email"],
  input[type="search"],
  input[type="tel"],
  select,
  #country-search-box,
  .form-control,
  textarea {
    font-size: 14px;
    border-radius: 8px;
  }

  .form-group .cover-label label {
    top: -9px;
    font-size: 12px;
  }

  .verify-btn {
    font-size: 12px;
    top: 12px;
  }

  .verify-btn i {
    margin-top: 0px;
    margin-right: 2px;
  }

  .verify-btn a.active img {
    width: 15px;
  }

  button.btn.button-primary.mt-5.mb-3 {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    font-size: 14px;
  }

  .or.mt-5.mb-5 {
    margin: 10px 0px 25px !important;
  }

  .soical-links a {
    font-size: 14px;
    padding: 13px !important;
    margin-bottom: 15px;
  }

  .soical-links a span.phone img {
    width: 11px;
  }

  .soical-links a span.apple img {
    width: 18px;
  }

  .soical-links a span.fb img {
    width: 18px;
  }

  .page-content .title .header {
    font-size: 18px;
    line-height: 35px;
  }

  .slider-tx {
    left: 60px;
    right: 60px;
    font-size: 16px;
    line-height: 28px;
    width: 500px;
  }

  .soical-links a span.email img {
    width: 18px;
  }

  p.smalls {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .modal-dialog.teamDetail-popup .modal-body h6 {
    width: 50%;
    font-size: 16px;
  }

  .cover-label span.currency-name {
    width: auto;
    line-height: 35px;
    top: 4px;
  }



  .title p {
    font-size: 14px;
  }

  .main_content .title .header,
  .modal-header .title .header,
  .page-content .title .header {
    font-size: 20px;
    line-height: 25px;
  }

  .modal-header {
    border-bottom: 0px;
    padding: 30px 30px 0px;
  }

  .modal-body {
    padding: 0px 30px 20px;
  }

  a.filter {
    padding: 7px 12px;
    font-size: 14px;
  }
  

  .modal-confirm .btn,
  .modal-confirm .btn:active {
    font-size: 14px;
  }

  input[type="text"],input[type="number"]
  input[type="password"],
  input[type="email"],
  input[type="search"],
  input[type="tel"],
  select,
  #country-search-box,
  .form-control,
  textarea {
    height: 45px;
  }

  .form-row {
    margin-right: 0px;
    margin-left: 0px;
  }

  .showDiv {
    height: 45px !important;
  }

  .btn {
    height: 45px;
    line-height: 22px;
    font-size: 16px;
    padding: 10px 35px;
  }

  .dropdown-menu.show .features-menu li a {
    padding: 10px 10px;
    font-size: 12px;
  }

  .header-right .dropdown-menu.show {
    top: 8px !important;
  }

  .dropdown-menu.show {
    width: 180px;
  }

  .main_content .title .smalls,
  .modal-header .title .smalls {
    margin: 5px 0px;
  }

  .icon_div:after {
    top: 20px;
  }

  /* team page */

  .page-heading .page-title {
    font-size: 20px;
    line-height: 24px;
  }

  .apply-btn {
    padding: 10px 20px;
    color: #fff !important;
  }

  .modal-footer.buttons {
    padding: 0px 30px 30px;
  }

  .title.mb-4.mt-1 {
    margin-bottom: 10px !important;
  }

  .modal-body ul.sortby li a {
    font-size: 16px;
    line-height: normal;
  }


  .ngx-pagination .disabled {
    padding: 6px 20px !important;
    font-size: 14px;
  }

  .ngx-pagination .current {
    padding: 6px 20px !important;
    font-size: 14px;
  }

  .ngx-pagination a {
    padding: 6px 20px !important;
    font-size: 14px;
  }

  .teamDetail-popup .modal-body ul li {
    margin-bottom: 15px;
  }

  .teamDetail-popup .modal-body ul li .modal-body h6 {
    font-size: 18px !important;
  }


  .filter-popup.modal-dialog {
    max-width: 450px;
  }

  .modal-dialog.sort-popup {
    max-width: 450px;
  }

  .modal-dialog.sort-popup .modal-header {
    padding: 30px 30px 20px;
  }

  .table thead th {
    padding: 12px 12px !important;
    font-size: 14px;
  }

  .table-bordered td a {
    padding: 12px 12px !important;
    font-size: 14px;
  }

}




/* media query for tab and mobile */

@media(max-width: 1200px) {

  .table-scroll {
    overflow-x: scroll;
  }

  .table {
    width: 1080px !important;
  }

  .soical-icons{top: 10px !important;}

  .slider-tx {
    left: 0;
    right: 0;
    font-size: 16px;
    line-height: 28px;
    max-width: 450px;
    padding: 50px;
  }


  /* profile-option css  */
  .full_lenght_right .container {
    width: 100%;
  }

  .main_content .header {
    padding-top: 20px;
    font-size: 20px;
  }

  .main_content .right-side {
    padding: 40px;
  }

  .btn-div{display: block !important;}
  a.btn-grey{width: 100% !important;
    min-width: 100% !important;
    display: block;
    margin-top: 10px;}

}


@media (max-width:1024px) {


  .main_content.login-section {
    display: block;
    margin-top: 0px;
  }

  .main_content .left-side {
    width: 100%;
    min-height: 300px;
    background-size: cover;
    background-position: top;
  }

  .slider-tx {
    max-width: 100%;
    width: 100%;
    padding: 20px;
    text-align: center;
  }

  .main_content .left-side::after {
    min-height: 300px;
  }

  .main_content .left-side {
    background: url(assets/images/img_banner_small.png) no-repeat padding-box;
  }

  .main_content .right-side {
    padding: 20px;
    margin: 0 auto;
    width: 100%;
    display: block;
  }

  .main_content .header {
    padding-top: 0px;
  }




}





@media (max-width: 1080px) {

  .sideBar-modal {
    max-width: 80%;
  }

  .editrolepopup.modal-dialog.modal-lg.sideBar-modal {
    width: 80% !important;
  }

  .modal-dialog.modal-lg.reassignlicensePopup.sideBar-modal {
    width: 80%;
  }


  .radio_box .radio_label {
    font-size: 14px;
  }

  .radio {
    top: 13px;
  }


  .modal-lg.sideBar-modal .modal-header {
    padding: 30px 40px 0px 40px;
    border-bottom: 0px;
  }

  .modal-lg.sideBar-modal .modal-body {
    padding: 10px 40px 30px 40px;
  }

  .payment-details span {
    display: block;
    margin-top: 5px;
    width: 60px;
    font-size: 18px;
  }

}


@media (max-width: 900px) {
  .manage.content-wrapper.sm-full-sr{
margin-left: 0px;
  }

  .ngx-otp-input {
    margin: 50px 15px 50px 0 !important;
  }

  .full_lenght_right .container {
    padding: 20px 20px;
    box-sizing: border-box;
    width: 100%;

  }

  .cover-label span.currency-name {
    font-size: 16px;
  }

  .option_container .option-box {
    width: 100%;
  }

  .option_container {
    flex-wrap: wrap;
  }

  .option_container .option_margin {
    margin-bottom: 30px !important;
    margin-right: 0px;
  }

  .w-8 {
    min-width: 350px !important;
  }

  .main {
    width: 100%;
    margin-left: 0px;
    padding: 0px;
    padding-top: 100px;
  }


  .calculate-popup .modal-content {
    padding: 20px !important;
    box-shadow: none !important;
  }


  a.menu_btn {
    display: none;
  }

  /* sidebar 900 */

  body.sidebar-mobile .page-sidebar {
    display: block;
    z-index: 2;
  }

  .header-right {
    padding: 10px 10px 10px 10px !important;
  }


  body.sidebar-mini .innerHeader .header-right {
    width: calc(100% - 80px) !important;
  }


  .innerHeader .header-right {
    width: calc(100% - 80px) !important;
  }

  .outer-logo.ex {
    width: 80px !important;
  }

  .content-wrapper {
    margin-left: 0px;
    padding: 80px 15px;
  }

  body.sidebar-mini .content-wrapper {
    margin-left: 0;
  }

  .page-sidebar {
    left: -380px;
  }


  /* .outer-logo {
  padding: 17px 10px !important;
} */

  .outer-logo.ex img {
    max-width: 50px;
  }



  body.sidebar-mini .page-sidebar {
    left: 0px;
    width: 250px !important;
    margin-top: 61px;
  }

  body.sidebar-mini .page-sidebar .side-menu>li>a>.nav-label p {
    display: block !important;
  }

  /* end  */

  .banner {
    min-width: 100% !important;
    height: 250px !important;
  }

  img.banner_img {
    display: none
  }

  .no-insight-block {
    display: block !important;
  }

  .banner_sub_text {
    top: 90px !important;
  }

  button.btn.button-primary.insight_btn {
    top: 135px !important;
  }

  .block {
    margin-bottom: 40px !important;
    display: block !important;
  }

.inner-sidebar.page-sidebar{
  width:100%!important;
  left: -100%!important;
  right: 0px!important;
  top: 0px!important;
  margin-top: 0px!important;
  }
  .sidebar-mini-show .inner-sidebar.page-sidebar{
    left: 0%!important;
  }
.inner-sidebar .manage-account{
  margin: 0px;
  padding: 25px;
}

.min-menu{
  display: block;
    width: 40px;
    height: 40px;
    background: #8174b236;
    border-radius: 50%;
    padding: 9px;
    margin-bottom: 15px;
}
.sidebar-mini-show .min-menu{
    z-index: unset !important;
}
.min-menu{
  position: absolute;
  top: 10px;
  left: 90px;
  z-index: 9;
}

.min-menu a span{
  width: 20px;
  height: 2px;
  background-color: #6d7c85;
  margin-top: 4px;
  display: block;
  transition: all .3s;
}

.min-menu span.icon-bar.line-first {
  width: 10px;
}

.min-menu span.icon-bar.line-third {
  width: 10px;
  margin-left: 10px;
}

}


@media(max-width: 767px) {

  .main_content {
    margin-top: 30px;
  }

  .main_content .left-side {
    display: none;
  }

  ul.button-wrap li input[type="radio"]+label {
    padding: 7px 15px;
  }

  .filter-title {
    font-size: 16px;
    line-height: 28px;
  }

  a.btn.button-primary.unfill-btn.edit-btn {
    padding: 10px 0px !important;
    font-size: 14px;
  }

  a.btn.button-primary.delete-btn {
    padding: 10px 0px !important;
    font-size: 14px;
  }


  .main_content .right-side {
    padding: 0px !important;
    padding-bottom: 30px !important;
  }

  .main {
    padding: 100px 15px;
  }

  /* header new csss  */

  .left-header a {
    display: inline-block !important;
  }


  .full_width_internal .container {
    padding: 20px 20px 0 20px;
  }


  .form-group.mt-5 {
    margin-top: 0px !important;
  }

  .selectItem {
    font-size: 14px;
  }

  .quitPopup .modal-content {
    padding: 20px !important;
    border-radius: 24px !important;
  }

  .question-mark {
    max-width: 70px;
  }

  .calculate-popup {
    max-width: 100%;
  }

  /* modal css  */

  .sideBar-modal {
    max-width: 100%;
  }

  .modal-lg.sideBar-modal .modal-header {
    padding: 20px 30px 0px 30px;
    border-bottom: 0px;
  }

  .modal-lg.sideBar-modal .modal-body {
    padding: 5px 30px 20px 30px;
  }

  .editrolepopup.modal-dialog.modal-lg.sideBar-modal {
    width: 100% !important;
  }

  .modal-dialog.modal-lg.reassignlicensePopup.sideBar-modal {
    width: 100%;
  }

  .form-group.bottom_btn .btn {
    max-width: 100%;
  }

  .cnt_btn {
    width: 100%;
  }

  .blueBox ul li button {
    height: 40px;
  }

  .btn-common {
    max-width: 200px;
  }

  .mapOuter {
    max-width: 100%;
    margin: 20px auto;
    padding: 10px;
  }

  .modal-dialog {
    max-width: 100%;
    margin: 20px auto;
    padding: 10px;
  }

  .small-title .date {
    display: block;
    margin-top: 8px;
    text-align: center;
    padding: 4px 10px;
    max-width: 135px;
  }

  .closeButton {
    right: 20px;
    top: 10px;
  }

  .ng-otp-input-wrapper.wrapper input {
    padding: 10px !important;
  }

  .showPass {
    top: 7px;
  }

  .modal-content {
    max-width: 100% !important;
    margin: 0 auto;
    border-radius: 10px !important;
  }

  .modal-footer.buttons {
    padding: 0px 20px 20px;
  }

  .clear-btn {
    padding: 15px 30px;
  }

  .modal-subHeading b {
    padding-right: 35px;
  }

  .tab-list ul {
    flex-wrap: wrap !important;
  }

  .tab-list ul li:last-child {
    padding-right: 0px;
    padding-left: 0px !important;
  }

  .time {
    flex-wrap: wrap;
  }

  .modal-subHeading {
    margin: 15px 0px !important;
  }




}


@media(max-width:499px) {

  .flexbox {
    display: block !important;
  }

  .col-left {
    position: relative;
    margin-bottom: 10px;
  }

  .content-wrapper {
    margin-left: 0px;
    padding: 80px 15px;
  }

  .page-heading .page-title {
    font-size: 18px;
    line-height: 24px;
  }

  a.add-btn {
    padding: 5px 10px !important;
    font-size: 12px !important;
  }


  .btn-common {
    max-width: 100% !important;
    margin: 10px 0px;
    margin-right: 0px !important;
  }

  .main_content {
    margin-top: 10px;
  }

  .cover-label span.currency-name {
    font-size: 14px;
  }

  .free {
    padding: 0px 20px;
  }

  .back-btn {
    margin-bottom: 0px !important;
  }

  .main_content .header {
    padding-top: 10px;
  }



  .maplocation {
    width: 350px;
    border-radius: 15px;
  }

  .modal-header {
    padding: 20px 20px 0px;
  }

  .modal-body {
    padding: 0px 20px 10px;
  }

  .map-show {
    height: 200px;
  }

  .table-box .grlistDropDwon.dropdown-menu.show {
    top: auto !important;
  }

  a.headermenuBtn {
    top: 14px !important;
    left: 75px !important;
  }

  .swal2-html-container {
    font-size: 16px !important;
  }

  .swal2-icon {
    width: 60px !important;
    height: 60px !important;
  }

  .swal2-icon .swal2-icon-content {
    font-size: 3em !important;
  }

  .grlistDropDwon.dropdown-menu.show {
    width: auto;
  }

  .quitPopup .modal-content {
    width: 300px;
    margin: 0 auto;
  }


  .main_content .title .header,
  .modal-header .title .header,
  .page-content .title .header {
    font-size: 18px;
    line-height: 22px;
  }

  .main_content .title .smalls,
  .modal-header .title .smalls {
    font-size: 14px;
  }

  .upload_section .up_opt {
    width: 180px;
    height: 130px;
  }

  .upload_section .up_opt p {
    padding: 25px 25px;
  }

  .title.mb-0.mt-3.col-12 {
    margin-top: 0px !important;
  }

  .tab-list ul {
    list-style: none;
    border-bottom: 1px solid #E9E9E9;
    margin: 0px 0px;
    padding: 0px;
    display: block;
    padding: 0px;
  }


}



/* end */

#sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff
}

#sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf
}

.swal-Alerts.bgButton .swal2-confirm {
  width: 145px !important;
}




/* n css*/
.btn-div {
  border-top: 1px dashed #D6D2D2;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
}

a.btn-grey {
  border: 1px solid #D8D8D8;
  border-radius: 32px;
  font: normal normal 500 14px/20px Quicksand;
  letter-spacing: 0.16px;
  color: #9A9A9A;
  padding: 10px 20px;
  min-width: 230px;
  text-align: center;
}

a.btn-grey.purple {
  color: #AC78A4
}

.modal-header .title .header {
  text-align: left;
}

.closeButton.rt {
  right: 0px;
  top: -10px;
}

.para-modal {
  text-align: left;
  font: normal normal medium 16px/20px Quicksand;
  letter-spacing: 0.64px;
  color: #111111;
}

a.ok-btn {
  letter-spacing: 0.64px;
  font: normal normal bold 14px/20px Quicksand;
  letter-spacing: 0.72px;
  color: #8373A4 !important;
  background: #F4F2FE 0% 0% no-repeat padding-box;
  border: 1px solid #8373A4 !important;
  border-radius: 32px;
  text-transform: capitalize;
  padding: 10px 20px;
  width: 100%;
  display: block;
  margin: 20px 0px;
}

.w-400 {
  width: 400px;
}

/* end */

.tab-list {
  cursor: pointer;
  position: relative;
}

.swal-Alerts.bgButton .swal2-confirm {
  width: 145px !important;
}

/* manage account common css */

.manage.content-wrapper {
  margin-left: 400px;
  padding-top: 100px;
}

.manage .title .header-2 {
  font: normal normal 700 24px/24px Quicksand;
  letter-spacing: 0.56px;
  color: #111111;
  opacity: 1;
}

.manage .title .header-2 a img{width: 20px;}
/* .heading-main {
  font: normal normal 600 20px/28px Quicksand;
  letter-spacing: 0.44px;
  color: #111111;
  border-bottom: 1px solid #DFDFDF;
  display: inline-block;
  padding-bottom: 20px;
} */

.manage-account {
  margin: 0 0 0 78px;
  padding-bottom: 20px;
}

.manage.content-wrapper {
  margin-left: 400px;
}

.manage .title .header-2 {
  font: normal normal 700 24px/24px Quicksand;
  letter-spacing: 0.56px;
  color: #111111;
  opacity: 1;
}


.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*=select-]:after,
.theme-green .bs-datepicker-body table td[class*=select-] span:after,
.theme-green .bs-datepicker-head {
  background-color: #8174B2;
}

.certi-div span img {
  width: auto;
  max-width: 200px;
  height: 90px;
}

.certi-div span {
  margin-right: 16px;
  margin-bottom: 10px;

}

.social-media {
  margin-bottom: 20px;
}

.social-media img {
  margin-right: 4px;
}

.social-media p {
  font: normal normal 700 14px/30px Quicksand;
  letter-spacing: 0px;
  color: #111111;
  margin-top: 4px;

}

.content-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.content-list li {
  position: relative;
  margin-left: 20px;
}

.content-list li::before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background-color: #BE8CB2;
  top: 10px;
  left: -20px;
}

.content-list li {
  font: normal normal 500 18px/28px Quicksand;
  letter-spacing: 0px;
  color: #5A5A5A;
}



.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*=select-]:after,
.theme-green .bs-datepicker-body table td[class*=select-] span:after,
.theme-green .bs-datepicker-head {
  background-color: #8174B2;
}
.theme-green .bs-datepicker-body table td span.disabled.selected{
  background-color: #ccc;
}

.theme-green .bs-datepicker-body table td span.bg-warning{
  background: #8174B2!important;
  color: #fff;
}
.manage .back-btn {
  margin-bottom: 5px;
  font: normal normal bold 13px/45px Quicksand;
}

.banner_img img {
  width: auto;
  height: auto;
  max-width: 85%;
}

.manage .title .para {
  font: normal normal 500 16px/30px Quicksand;
  letter-spacing: 0.18px;
  color: #5A5A5A;
  width: 85%;
  margin-top: 16px;
}

.para-purple {
  font: normal normal 500 18px/34px Quicksand;
  letter-spacing: 0.2px;
  color: #8174B2;
}

.header.sub-heading.date {
  font: normal normal bold 16px/28px Quicksand;
  letter-spacing: 0.44px;
  color: #111111;
  margin-top: 100px;
}

.manage .row.privacy {
  border-bottom: 1px solid #DFDFDF;
}

.heading-5 {
  text-decoration: underline;
  text-decoration-color: #111;
  font: normal normal bold 22px/28px Quicksand;
  letter-spacing: 0.44px;
  color: #111111;
  margin-top: 20px;
}

.para-p {
  font: normal normal 500 16px/30px Quicksand;
  letter-spacing: 0.18px;

  color: #5A5A5A;
}

.content-list.small li {
  color: #111111;
  font: normal normal 600 14px/30px Quicksand;
}

.content-list.small li::before {
  position: absolute;
  content: '';
  height: 7px;
  width: 7px;
  border-radius: 50px;
  background-color: #BE8CB2;
  top: 12px;
  left: -20px;
}

.clr-text {
  background: transparent linear-gradient(260deg, #F4F7EB 0%, #FFF4EC 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 15px 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.clr-text p {
  font: normal normal 600 16px Quicksand;
  letter-spacing: 0px;
  color: #182138;
  margin-bottom: 0px;
  color: #5A5A5A;
}

.manage .contact-us .form-group {
  margin: 20px 0px;
}

.modal-confirm.w-3 .modal-content {
  padding: 40px;
}

.modal-confirm.w-3 .timeShow {
  min-width: 196px
}

.modal-confirm.w-3 .time-tab {
  margin: 32px 0px;
}

.bank-details {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
}

.bank-details .heading {
  font: normal normal 700 18px/28px Quicksand;
  letter-spacing: 0.44px;
  color: #111111;
}

.bank-details p {
  font: normal normal 500 14px/30px Quicksand;
  letter-spacing: 0px;
  color: #111111;
}



.bank-details p.bold{font-weight: 700;}

body.sidebar-mini .manage-account .heading-main {
  font-size: 16px;
  line-height: 16px;
  padding-top: 30px;
}

body.sidebar-mini .page-sidebar .manage-account {
  margin-left: 8px !important;
}

.calender-pagination .pagination-wrapper .ngx-pagination li{
  display: none!important;
}
.calender-pagination .pagination-wrapper .ngx-pagination li:first-child, .calender-pagination .pagination-wrapper .ngx-pagination li:first-child a{
  display: block!important;
  width: 25px;
    height: 24px;
    font-size: 0px!important;
    padding: 0px!important;
    position: absolute;
    left: 6px;
    top: 32px;
    z-index: 3;
    border:0px;
    background:url('./assets/images/ic_arrow_left_black.png');
    background-repeat: no-repeat;

}
.calender-pagination .pagination-wrapper .ngx-pagination li:last-child a:hover{
  background-color: inherit;
}

.calender-pagination .pagination-wrapper .ngx-pagination li:first-child a{
  left: 0px;
  top: 0px;
  overflow: 0;
}

.calender-pagination .pagination-wrapper .ngx-pagination li:last-child, .calender-pagination .pagination-wrapper .ngx-pagination li:last-child a{
  display: block!important;
  width: 25px;
    height: 24px;
    font-size: 0px!important;
    padding: 0px!important;
    position: absolute;
    right: 2px;
    top: 32px;
    z-index: 3;
    background:url('./assets/images/ic_arrow_right_black.png');
    background-repeat: no-repeat;
   border:0px

}
.calender-pagination .pagination-wrapper .ngx-pagination li:last-child a{
  right: 0px;
  top: 0px;
  overflow: 0;
}

.calender-pagination .pagination-wrapper{
  position: absolute;
  top: 0px;
  bottom:inherit!important
}

.calender-pagination .pagination-previous.disabled, .calender-pagination .pagination-next.disabled{
opacity: 0.65;
}
.no-filtered-data h5{
  font-size: 15px
}

.modal-opn .noticationOuter {
  display: block!important;
}
.modal-opn .form-group{
  text-align: center;
}
.btn.button-primary.small{
  max-width: 150px;
}
.modal-opn{
  overflow: hidden;
}
.hideFaq.faq{
  display: none;
}
.faq.hideFaqs{
  display: none;
}

.kr-payment-button{
  background-color: #8174B2;
  border-color: #8174B2;
  color: rgb(0, 0, 0);
  border-radius: 25px;
}
.multiselect-item-checkbox input[type=checkbox] + div:before{
  border: 2px solid #8174B2!important;
  color: #8174B2!important;
}

.multiselect-item-checkbox input[type=checkbox]:checked + div:before{
  -webkit-animation: .2s ease-in borderscale;
  animation: .2s ease-in borderscale;
  background: #8174B2!important;
}

/* ---- prevent input type number -> Up/Down arrow css -------- */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}